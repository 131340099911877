body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root {
  height: 100%;
}

a {
  cursor: pointer;
}


.fc th.fc-col-header-cell.fc-day {
  border-color: transparent;
}

.fc tr.fc-scrollgrid-section > td {
  border-color: transparent;
}

.fc.fc table  {
  border-color: transparent;
}

.fc th.fc-timegrid-axis {
  border-color: transparent;
}

.fc .fc-toolbar-title {
  text-transform: uppercase;
  color: grey;
  font-weight: 400;
  letter-spacing: 1pt;
}

.fc-event-main {
  overflow: hidden;
}

.fc td.fc-timegrid-slot-minor.fc-timegrid-slot-minor {
  border: none;
}

.MuiSvgIcon-root.custom * {
  stroke: currentColor !important;
}

.pace .pace-progress {
  background-color: rgb(168, 128, 9);
}
